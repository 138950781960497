import React, {useEffect, useState} from 'react';
import ServerListingService from "../../services/ServerListingService";
import EmbeddedLoadingScreen from "../../widgets/jsx/EmbeddedLoadingScreen";
import ServerView from "../../widgets/jsx/ServerView";
import {Link} from "react-router-dom";
import {MaterialButtonOutlined, MaterialButtonTonal} from "../../widgets/jsx/MaterialButton";

function Servers() {
    const [servers, getServers] = useState(undefined)
    const [d, gd] = useState(undefined)

    useEffect(() => {
        if (servers !== undefined) {
            // T

            let x = []

            x.push({
                id: 0,
                name: "teslasoft-main",
                ram: 3072 + "MB",
                disk: "∞",
                status: "active",
                price: "$4.99/month",
                ip: "82.163.176.145",
                region: "UNITED KINGDOM",
                type: "hosting",
                system: "linux"
            });

            x.push({
                id: 0,
                name: "i-visit",
                ram: 8192 + "MB",
                disk: "150GB",
                status: "active",
                price: "$13.00/month",
                ip: "107.152.32.242",
                region: "UNITED STATES",
                type: "vm",
                system: "linux"
            });

            x.push({
                id: 0,
                name: "mc",
                ram: 4096 + "MB",
                disk: "100GB",
                status: "active",
                price: "$18.10/month",
                ip: "162.212.157.209",
                region: "UNITED STATES",
                type: "vm",
                system: "linux"
            });

            servers.droplets.forEach((e) => {
                x.push({
                    id: e.id,
                    name: e.name,
                    ram: e.memory + "MB",
                    disk: e.disk + "GB",
                    status: e.status,
                    price: "$" + e.size.price_monthly + "/month",
                    ip: e.networks.v4[1].ip_address,
                    region: e.region.name,
                    type: "virtual machine",
                    system: e.image.distribution,
                });
            })

            gd(x)
        }
    }, [servers]);

    return (
        <>
            <h1 className={"activity-title"}>Servers</h1>
            <div>{ d !== undefined ? d.map((x) => <ServerView key={x.id}
                                                                name={x.name}
                                                                ip={x.ip}
                                                                ram={x.ram}
                                                                disk={x.disk}
                                                                status={x.status}
                                                                price={x.price}
                                                                region={x.region}
                                                                type={x.type}
                                                                system={x.system}
            />) : <EmbeddedLoadingScreen/> }</div>
            <ServerListingService getData = { getServers }/>
            <div className={"btn-panel"}>
                <a className={"btn-link"} href="https://cloud.digitalocean.com/projects/f8a123e1-39b3-4c95-8601-95b39119d7c2/resources?i=9af0d9">
                    <MaterialButtonTonal className={"button"} variant="contained" ><p className={"btn-tonal btn-mtrl"}>DigitalOcean dashboard</p></MaterialButtonTonal>
                </a>
                <a className={"btn-link"} href="https://dash.cloudflare.com/">
                    <MaterialButtonTonal className={"button"} variant="contained" ><p className={"btn-tonal btn-mtrl"}>Cloudflare dashboard</p></MaterialButtonTonal>
                </a>
                <a className={"btn-link"} href="https://cpanel.teslasoft.org/">
                    <MaterialButtonTonal className={"button"} variant="contained" ><p className={"btn-tonal btn-mtrl"}>CPanel dashboard</p></MaterialButtonTonal>
                </a>
                <Link className={"btn-link"} to="/">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
                </Link>
            </div>
        </>
    );
}

export default Servers;
